import React, { ReactElement, ReactNode } from 'react'
import { GetStaticProps } from 'next'
import { dehydrate, QueryClient } from '@tanstack/react-query'
import { getCMSPage } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import ShopOnboardingRedirect from '@mindfulchefuk/features/ShopOnboarding/components/ShopOnboardingRedirect'
import { getCMSGlobalContent } from '@mindfulchefuk/features/CMS/services/getCMSGlobalContent'
import { ChooseRecipesLayout } from '@mindfulchefuk/features/Onboarding/components/layout/ChooseRecipesLayout'

const OnboardingShop = (): ReactElement | null => {
  return <ShopOnboardingRedirect />
}

OnboardingShop.getLayout = (page: ReactNode) => (
  <ChooseRecipesLayout>{page}</ChooseRecipesLayout>
)

export default OnboardingShop

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()
  await queryClient.prefetchQuery(['cms-page-shop-onboarding'], () =>
    getCMSPage('page-shop-onboarding')
  )
  await queryClient.prefetchQuery(['cms-global-content'], getCMSGlobalContent)

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: parseInt(process.env.NEXT_PUBLIC_CMS_REVALIDATE_INTERVAL, 10),
  }
}
