import {
  logEvent,
  logEvents,
  logProductClick,
  logPurchase,
} from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { transformRecipeItems } from '@mindfulchefuk/utils/analytics/transformRecipeItems'
import { Device } from '@mindfulchefuk/hooks/useDetectDevice'

interface CheckoutStartProps {
  items: Recipe[]
  price: number
  totalWithDiscount: number
}

interface RecipeClickProps {
  foodGroup: string
  portions: number
  position: number
  price: number
  id: string
}

interface CheckoutSuccessProps {
  totalWithDiscount: number
  total: number
  savedVoucherCode: string
  items: Recipe[]
  portionCount: number
  selectedMealPlan: string
  customerId: string
  isWaitrose: boolean
}

export const logCheckoutStart = ({
  items,
  price,
  totalWithDiscount,
}: CheckoutStartProps): void => {
  logEvents([
    {
      category: ANALYTICS_CATEGORIES.buttonClick,
      action: 'PlaceOrder-Clicked',
      label: 'Sticky Bottom Right',
    },
    {
      category: ANALYTICS_CATEGORIES.chooseRecipes,
      action: 'Number of Recipes Chosen',
      label: items.length.toString(),
    },
    {
      category: ANALYTICS_CATEGORIES.chooseRecipes,
      action: 'Combined Total of Recipes Chosen',
      label: `Total: ${price} (with discount: ${totalWithDiscount})`,
    },
  ])

  if (totalWithDiscount !== 0) {
    logEvent({
      category: ANALYTICS_CATEGORIES.chooseRecipes,
      action: 'Discount Amount Applied',
      label: (price - totalWithDiscount).toString(),
    })
  }
}

export const logSocialCheckoutStart = (): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'InitiateCheckout')
  }
}

export const logSocialShippingPage = (): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'CompleteRegistration')
  }
}

export const logDeliveryDateChange = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.chooseRecipes,
    action: 'Date Selection Dropdown',
    label: 'Click',
  })
}

export const logMealPlanChange = (newMealPlan: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.chooseRecipes,
    action: 'Meal Plan Dropdown',
    label: newMealPlan.toString(),
  })
}

export const logPortionCountChange = (newPortionCount: number): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.chooseRecipes,
    action: 'Portion Count Dropdown',
    label: newPortionCount.toString(),
  })
}

export const logRecipesSortChange = (newSortBy: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.chooseRecipes,
    action: 'Recipes Sort Dropdown',
    label: newSortBy,
  })
}

export const logRecipeClick = ({
  foodGroup,
  portions,
  position,
  price,
  id,
}: RecipeClickProps): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.recipe,
    action: 'Modal Opened',
    label: 'Click',
  })

  logProductClick({
    list: 'Onboarding',
    brand: 'Recipe',
    category: foodGroup,
    id,
    name: `Recipe Id ${id}`,
    variant: portions,
    position,
    price,
  })
}

export const logModalClose = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.recipe,
    action: 'Modal Closed',
    label: 'Click',
  })
}

export const logCheckoutErrors = (errors: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutShipping,
    action: 'Go to Payment - Click',
    label: errors,
  })
}

export const logCheckoutStep = (step: number): void => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'mc.analytics.checkout.step',
      ecommerce: {
        checkout: {
          actionField: { step },
        },
      },
    })
  }
}

export const logVoucherCodeRemoved = (page: string): void => {
  logEvents([
    {
      category: ANALYTICS_CATEGORIES.onboarding,
      action: 'VoucherCodeRemove-Clicked',
      label: page,
    },
    {
      category: ANALYTICS_CATEGORIES.onboarding,
      action: 'Manually Removed Voucher Code',
      label: 'Click',
    },
  ])
}

export const logVoucherCodeAdded = (
  voucherCode: string,
  page: string
): void => {
  logEvents([
    {
      category: ANALYTICS_CATEGORIES.onboarding,
      action: 'VoucherCodeApply-Clicked',
      label: page,
    },
    {
      category: ANALYTICS_CATEGORIES.onboarding,
      action: 'Manually Added Voucher',
      label: voucherCode,
    },
  ])
}

export const logVoucherCodeChanged = (voucherCode: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.onboarding,
    action: 'Voucher Code Changed',
    label: voucherCode,
  })
}

export const logInvalidVoucherCode = (voucherCode: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.onboarding,
    action: 'Voucher Code Invalid',
    label: voucherCode,
  })
}

export const logMarketingBoxUntick = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutAboutYou,
    action: 'Email Opt-In Untick',
    label: 'Click',
  })
}

export const logInvalidEmailEntered = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutAboutYou,
    action: 'Email Validation Error',
    label: 'Error',
  })
}

export const logSocialLeadCreated = (): void => {
  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Lead')
  }
}

export const logExistingCustomerClick = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutAboutYou,
    action: 'Existing Customer Button',
    label: 'Click',
  })
}

export const logInvalidCardDetails = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.onboarding,
    action: 'InvalidCreditCard',
    label: 'Payment Details',
  })
}

export const logCardInputCompleted = (element: string): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutPayment,
    action: 'Credit Card Input Completed',
    label: element,
  })
}

export const logDynamicAddressSearchUsed = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutShipping,
    action: 'Dynamic Address Search Used',
  })
}

export const logPlaceOrderButtonClick = (): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutPayment,
    action: 'click',
    label: 'Place Order Button Clicked',
  })
}

export const logCheckoutSuccess = ({
  totalWithDiscount,
  total,
  savedVoucherCode,
  items,
  portionCount,
  selectedMealPlan,
  customerId,
  isWaitrose,
}: CheckoutSuccessProps): void => {
  logPurchase({
    transactionId: `NEW RB-${customerId}`,
    total: totalWithDiscount || total,
    affiliation: 'Website',
    coupon: savedVoucherCode,
    products: transformRecipeItems({
      items,
      portionCount,
      voucherCode: savedVoucherCode,
    }),
    isWaitrose,
  })

  if (typeof window.fbq === 'function') {
    window.fbq('track', 'Purchase', {
      value: totalWithDiscount || total,
      currency: 'GBP',
      content_ids: [selectedMealPlan, null, portionCount],
    })
  }

  if (typeof window.adalyserTracker === 'function') {
    window.adalyserTracker('trackEvent', 'lce3', {}, true)
  }

  if (typeof window.AWIN === 'object') {
    window.AWIN.Tracking.Sale = {
      amount: parseFloat(`${total}`).toFixed(2),
      channel: 'aw',
      orderRef: customerId,
      parts: `DEFAULT:${parseFloat(
        `${totalWithDiscount}` || `${total}`
      ).toFixed(2)}`,
      currency: 'GBP',
      voucher: savedVoucherCode || '',
      test: '0',
    }
    window.AWIN.Tracking.run()
  }

  if (typeof window.optimizely === 'object') {
    window.optimizely.push({
      type: 'event',
      eventName: 'onboarding_checkout_success',
      tags: {
        customerId,
        coupon: savedVoucherCode,
        revenue: (totalWithDiscount || total) * 100, // £54.68 > 5468
      },
    })
  }
}

export const logAppDownloads = (device: Device): void => {
  logEvent({
    category: ANALYTICS_CATEGORIES.checkoutSuccess,
    action: 'App Download Icon Clicked',
    label: device,
  })
}
