type Comparator<Item> = (element: Item, step: Item) => boolean

/**
 * Unique array based on a comparator
 *
 * Based on https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_uniqwith
 */
export const uniqWith = <Item>(arr: Item[], comparator: Comparator<Item>) =>
  arr.filter(
    (element, index) =>
      arr.findIndex((step) => comparator(element, step)) === index
  )
