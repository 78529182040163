import {
  Recipe,
  RecipeWithQuantity,
} from '@mindfulchefuk/features/Recipes/interfaces'
import { ProductData } from '@mindfulchefuk/utils/analytics/interfaces'
import { uniqWith } from '@mindfulchefuk/utils/array/uniqWith'

interface TransformRecipeItemsArgs {
  items: Recipe[]
  portionCount: number
  voucherCode?: string
}

interface TransformRecipeItemsWithQuantityArgs {
  items: RecipeWithQuantity[]
  portionCount: number
}

export const transformRecipeItems = ({
  items,
  portionCount,
  voucherCode = '',
}: TransformRecipeItemsArgs): ProductData[] => {
  const recipes = items.map(({ foodGroup, price, id, title }) => ({
    brand: 'recipe box',
    category: foodGroup,
    currencyCode: 'GBP',
    id,
    name: title,
    quantity: 1,
    variant: portionCount,
    price,
    coupon: voucherCode.length > 0 ? voucherCode : undefined,
  }))
  const uniqueRecipes = uniqWith(recipes, (recipe1, recipe2) => {
    return recipe1.id === recipe2.id
  })
  const recipesWithQuantities = uniqueRecipes.map((recipe) => {
    const quantity = recipes.filter(({ id }) => {
      return id === recipe.id
    }).length

    return {
      ...recipe,
      quantity,
    }
  })

  return recipesWithQuantities
}

export const transformRecipeItemsWithQuantity = ({
  items,
  portionCount,
}: TransformRecipeItemsWithQuantityArgs): ProductData[] =>
  items.map(({ foodGroup, price, id, title, quantity }) => ({
    brand: 'recipe box',
    category: foodGroup,
    currencyCode: 'GBP',
    id,
    name: title,
    quantity,
    variant: portionCount,
    price,
  }))
